export const Layout = () => import('../../components/Layout.vue' /* webpackChunkName: "components/layout" */).then(c => wrapFunctional(c.default || c))
export const PreprPage = () => import('../../components/PreprPage.vue' /* webpackChunkName: "components/prepr-page" */).then(c => wrapFunctional(c.default || c))
export const PressInfo = () => import('../../components/PressInfo.vue' /* webpackChunkName: "components/press-info" */).then(c => wrapFunctional(c.default || c))
export const AboutEo = () => import('../../components/AboutEo/AboutEo.vue' /* webpackChunkName: "components/about-eo" */).then(c => wrapFunctional(c.default || c))
export const EoBackgroundSheet = () => import('../../components/EoBackgroundSheet/EoBackgroundSheet.vue' /* webpackChunkName: "components/eo-background-sheet" */).then(c => wrapFunctional(c.default || c))
export const PressNewsletter = () => import('../../components/PressNewsletter/PressNewsletter.vue' /* webpackChunkName: "components/press-newsletter" */).then(c => wrapFunctional(c.default || c))
export const ServiceForm = () => import('../../components/ServiceForm/ServiceForm.vue' /* webpackChunkName: "components/service-form" */).then(c => wrapFunctional(c.default || c))
export const VolunteerForm = () => import('../../components/VolunteerForm/VolunteerForm.vue' /* webpackChunkName: "components/volunteer-form" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
